<template>
  <div id="mrf-report">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>
      <v-row wrap>
        <v-col cols="12" md="6" lg="12" align="center" style="margin-left: 40%">
          <v-btn
            to="/events-bidding/create"
            color="#1db954"
            elevation="30"
            rounded
            class="font-size-h6 mr-3 my-3 white--text d-inline-flex align-center"
            >Create</v-btn
          >
          <v-btn
            medium
            @click.prevent="refreshPageData"
            color="#a4c639"
            elevation="30"
            rounded
            class="font-size-h6 mr-3 my-3 white--text"
            >&nbsp; Refresh &nbsp;
          </v-btn>
        </v-col>
      </v-row>
      <v-container class="py-0" v-if="tableData1.length > 0">
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <h4>{{ tableData1.length }} Records found.</h4>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <br />
            <v-data-table
              class="elevation-1"
              v-model="selected"
              :search="search"
              :item-key="tableOptions1.ItemKey"
              :single-select="tableOptions1.SingleSelectFlag"
              :headers="tableColumns1"
              :items="tableData1"
              :items-per-page="tableOptions1.ItemsPerPage"
              :footer-props="tableOptions1.FooterProps"
            >
              <template v-slot:item.StatusTxt="{ item }">
                <v-chip :color="item.StatusColor" draggable>{{
                  item.StatusTxt
                }}</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :href="item.FilePath"
                      target="_blank"
                      class="mx-2"
                      icon
                      dark
                      small
                      color="#a41623"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark> mdi-file-pdf-box </v-icon>
                    </v-btn>
                  </template>
                  <span> Download </span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.DeleteFlag">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      icon
                      size="25px"
                      color="red"
                      @click="deleteConfirmAlert(item, e)"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
// import EventBiddingCreate from "./EventBiddingCreate.vue";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      OverlayLoadingFlag: false,
      LoadingFlag: false,
      SearchFlag: false,
      uploadPercentage: 0,

      addRecordPrompt: false,
      EditRecordPrompt: false,

      EventTypeRules: [],
      EventType: "",
      EventTypeOptions: [
        { value: "", text: "- Select -" },
        { value: 1, text: "National Events" },
        { value: 2, text: "Zone Events" },
        // { value: 3, text: "Lom Events" }
      ],
      EventTypeOptionsLoading: false,

      FromDate: new Date().toISOString().slice(0, 10),
      Modal1: false,
      LomName: "",
      LoCode: "",
      ZoneCode: "",
      ZoneName: "",

      ToDate: new Date().toISOString().slice(0, 10),
      Modal2: false,

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      selectedData: {},

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;
        this.getTableRecords();
        this.pageData();
      }
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "events_bid",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.getTableRecords();
    },
    pageData() {
      console.log("refreshPageData called");

      var ZoneName = this.$session.get("ZoneName");
      ZoneName = ZoneName == (null || undefined) ? "" : ZoneName;
      console.log({ ZoneName });
      this.ZoneName = ZoneName;

      var ZoneCode = this.$session.get("ZoneId");
      ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
      console.log({ ZoneCode });
      this.ZoneCode = ZoneCode;

      var LoCode = this.$session.get("LomId");
      LoCode = LoCode == (null || undefined) ? "" : LoCode;
      console.log({ LoCode });
      this.LoCode = LoCode;

      var LomName = this.$session.get("LomName");
      LomName = LomName == (null || undefined) ? "" : LomName;
      console.log({ LomName });
      this.LomName = LomName;
    },
    getTableRecords() {
      console.log("getTableRecords called");
      var ZoneCode = this.$session.get("ZoneId");
      ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
      console.log({ ZoneCode });
      this.ZoneCode = ZoneCode;

      var LoCode = this.$session.get("LomId");
      LoCode = LoCode == (null || undefined) ? "" : LoCode;
      console.log({ LoCode });
      this.LoCode = LoCode;

      var ZoneCode = this.ZoneCode;
      var LoCode = this.LoCode;

      console.log("LoCode=" + LoCode + ", ZoneCode=" + ZoneCode);
      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData1 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/events/bidding/lists";
      var upload = {
        UserInterface: 2,
        // ZoneCode: this.ZoneCode,
        LoCode: this.LoCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = {};
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          console.log("output=" + output + ", flag=" + flag);

          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            var options = records.TableOptions;
            console.log({ options });
            thisIns.tableOptions1 = options;
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableData1 = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          console.log("error=" + error);
          thisIns.toast("error", error);
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#mrf-report {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>