<template>
  <div id="lom-list">
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
        v-if="SpeedDialFlag"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <v-btn fab dark small color="indigo" @click.prevent="addRecord">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-speed-dial>
    </v-container>
    <v-dialog v-model="memberDocumentPrompt" persistent scrollable>
      <member-document-list
        v-if="memberDocumentPrompt"
        :record="selectedData"
        :pageDescription="memberDocumentTitle"
        @hideDialog="hideMemberDocumentPrompt($event)"
      ></member-document-list>
    </v-dialog>

    <v-dialog v-model="previewRecordPrompt" max-width="75%" persistent>
      <directory-informatiom-preview
        :previewRecordPrompt="previewRecordPrompt"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
        :recordData="selectedData"
        v-if="previewRecordPrompt"
      ></directory-informatiom-preview>
    </v-dialog>

    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4></v-card-subtitle
      >

      <v-card-text>
        <v-row wrap>
          <v-col align="start" cols="12" md="12">
            <div class="notes-content">
              <p><span class="text-danger">*</span> indicates required field</p>
            </div>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              class="font-size-h6 mr-3 white--text"
              @click.prevent="refreshPageData"
              color="#a4c639"
            >
              Refresh
            </v-btn>
            <v-btn
              v-if="RoleId == 2"
              elevation="30"
              shaped
              tile
              small
              class="font-size-h6 mr-3 white--text"
              @click.prevent="regenerateRecords"
              :loading="SubmitFlag"
              color="#db4437"
            >
              Regenarate
            </v-btn>
          </v-col>
        </v-row>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form
            ref="form2"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="searchForm"
          >
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Year</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="JciYearCode"
                  :rules="JciYearCodeRules"
                  :items="JciYearCodeOptions"
                  :loading="JciYearCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" sm="6" lg="4" md="4">
                <label>
                  <h6><span class="text-danger">*</span> Area</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="AreaCode"
                  :rules="AreaCodeRules"
                  :items="AreaCodeOptions"
                  :loading="AreaCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6> Zone <small> (optional)</small></h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="ZoneCode"
                  :rules="ZoneCodeRules"
                  :items="ZoneCodeOptions"
                  :loading="ZoneCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" sm="6" lg="4" md="4">
                <label> <h6>Region</h6> </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="RegionCode"
                  :rules="RegionCodeRules"
                  :items="RegionCodeOptions"
                  :loading="RegionCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>LOM <small> (optional)</small></h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="LomCode"
                  :rules="LomCodeRules"
                  :items="LomCodeOptions"
                  :loading="LomCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>Designation <small> (optional)</small></h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="DesignationCode"
                  :rules="DesignationCodeRules"
                  :items="DesignationCodeOptions"
                  :loading="DesignationCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>Approval Status <small> (Optional)</small></h6>
                <v-select
                  :reduce="(option) => option.value"
                  :items="ApprovalStatusOptions"
                  :rules="ApprovalStatusRules"
                  v-model="ApprovalStatus"
                  dense
                  required
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>Migration Status <small> (Optional)</small></h6>
                <v-select
                  :reduce="(option) => option.value"
                  :items="MigrationStatusOptions"
                  :rules="MigrationStatusRules"
                  v-model="MigrationStatus"
                  dense
                  required
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  medium
                  class="btn btn-primary font-size-h6 px-4 py-4 my-3 mr-3 white--text"
                >
                  Search
                </v-btn>
                <v-btn
                  @click.prevent="resetForm"
                  color="#00a4ef"
                  elevation="30"
                  medium
                  class="btn btn-primary font-size-h6 px-4 py-4 my-3 mr-3 white--text"
                >
                  Reset
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ tableData1.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="DownloadFlag"
                color="info"
                @click.prevent="
                  generateExcel(
                    tableData1,
                    ExcelFields,
                    ExcelFileName,
                    'My Worksheet'
                  )
                "
                class="mr-4"
                >Download</v-btn
              >
            </v-col>
            <p></p>
          </v-row>
          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelDownloadFlag == true && ExcelFileFlag == false"
                  @click.prevent="generateReportExcel"
                  :loading="ExcelLoadingFlag"
                  target="_blank"
                  color="#ff7f50"
                >
                  Generate Report
                </v-btn>
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelFileName != '' && ExcelFileFlag == true"
                  :href="ExcelFileName"
                  target="_blank"
                  color="#2fb65a"
                >
                  <v-icon dark> mdi-download </v-icon> Download Report
                </v-btn>
              </v-col>
            </v-row>
            <p></p>
          </v-container>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :loading="TableLoadingFlag"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template
                  v-slot:[`item.YearwiseOfficeHeldStatusTxt`]="{ item }"
                >
                  <v-chip
                    :color="
                      getActiveStatusColor(item.YearwiseOfficeHeldStatusTxt)
                    "
                    draggable
                    dark
                    >{{ item.YearwiseOfficeHeldStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:[`item.ProfilePic`]="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-file-search</v-icon
                      >
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteAlert(item)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-delete</v-icon
                      >
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                  <!-- <v-tooltip bottom v-if="item.MemberImagePath">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.MemberImagePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-image-area </v-icon>
                      </v-btn>
                    </template>
                    <span> Member Image Download </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.MemberDocumentFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="memberDocumentData(item, e)"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark> mdi-account-box </v-icon>
                      </v-btn>
                    </template>
                    <span> View Documents </span>
                  </v-tooltip> -->
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import companyConfig from "@/company_config.json";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import LomPresidentAssign from "@/view/pages/erp/lom-president/LomPresidentAssign.vue";
import LomDocumnets from "@/view/pages/erp/lom/LomDocumnets.vue";
import MemberDocumentList from "@/view/pages/members/MemberDocumentList.vue";
import DirectoryInformatiomPreview from "./DirectoryInformatiomPreview.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    LomPresidentAssign,
    LomDocumnets,
    MemberDocumentList,
    DirectoryInformatiomPreview,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SpeedDialFlag: false,
      TableLoadingFlag: false,

      memberDocumentPrompt: false,
      memberDocumentTitle: "",

      ExcelFileFlag: false,

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      rows: [],

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      excel_fields: {
        "Lom Id": "LomId",
        "Zone Id": "ZoneId",
        "Lom Name": "LomName",
        "President Name": "LomPresidentFullName",
        "LOM NAME": "LomName",
        "Mobile No": "LomPresidentMobileNo",
        "Email Id": "LomPresidentEmailid",
      },

      recordToEdit: "",
      record: {},
      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      editPageDescription: "Assign LOM President for JCI India",

      selectedData: {},

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      AreaCodeRules: [(v) => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCodeRules: [],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      LomCodeRules: [],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      DesignationCodeRules: [],
      DesignationCode: "",
      DesignationCodeOptions: [],
      DesignationCodeOptionsLoading: false,

      ApprovalStatusRules: [],
      ApprovalStatus: "",
      ApprovalStatusOptions: [
        { value: "", text: "--Select--" },
        { value: 1, text: "Approved" },
        { value: 2, text: "Pending" },
        { value: 3, text: "Rejected" },
        { value: 4, text: "Cancelled" },
      ],

      MigrationStatusRules: [],
      MigrationStatus: "",
      MigrationStatusOptions: [
        { value: "", text: "--Select--" },
        { value: 0, text: "Not migrated" },
        { value: 1, text: "Migrated" },
      ],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      RoleId: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.CurrentYearId = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        console.log({ RoleId });
        // RoleId = 4;
        this.RoleId = RoleId;

        this.JciYearCodeOptionsLoading = true;
        this.getYearCodeOptions();
        this.getDesignationCodeOptions();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    JciYearCode: function () {
      console.log("watch JciYearCode");
      // this.getAreaCodeOptions();
      this.ZoneCode = "";
      this.getZoneCodeOptions();
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.LoadingFlag = false;
      this.AreaCodeOptionsLoading = false;
    },
    AreaCode: function () {
      console.log("watch AreaCode");
      // this.getZoneCodeOptions();
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");
      // this.getRegionCodeOptions();
      this.LomCode = "";
      this.getLomCodeOptions();
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.LoadingFlag = false;
      this.RegionCodeOptionsLoading = false;
    },
    RegionCode: function () {
      console.log("watch RegionCode");
      // this.getLomCodeOptions();
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LoadingFlag = false;
      this.LomCodeOptionsLoading = false;
    },
    DesignationCodeOptions: function () {
      console.log("watch DesignationCodeOptions");
      this.LoadingFlag = false;
      this.DesignationCodeOptionsLoading = false;
    },
  },
  methods: {
    hideMemberDocumentPrompt(flag) {
      console.log("hideMemberDocumentPrompt called");
      console.log({ flag });
      this.memberDocumentPrompt = false;
      if (flag) {
        // this.member.document_flag = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.$refs.form2.reset();
      this.tableData1 = [];
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.searchForm();
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.searchForm();
    },
    memberDocumentData(tr) {
      console.log("memberDocumentData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.memberDocumentTitle = "Documents for " + tr.MemberName;
        this.memberDocumentPrompt = true;
      } else {
        var message = "Kindly select one member.";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt(flag) {
      console.log("hidePreviewRecordPrompt called");
      console.log({ flag });
      this.previewRecordPrompt = false;
      if (flag) {
        this.searchForm();
      }
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lgb_dis",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getYearCodeOptions() {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = this.YearCode;
      console.log("CurrentYearId=" + CurrentYearId);
      this.LoadingFlag = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        where: [["jci_year_id", ">=", 11]],
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      var yearCode = this.CurrentYearId;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.AreaCodeOptionsLoading = true;
        var selectbox1_source = "AreaCode";
        var selectbox1_destination = "AreaCodeOptions";
        var selectbox1_url = "api/year-wise-lom/area-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Year should not be empty";
        this.toast("error", message);
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.CurrentYearId;
      var areaCode = this.AreaCode;
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      var YearCode = this.CurrentYearId;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode
      );
      if (YearCode != "" && AreaCode != "" && ZoneCode != "") {
        this.LoadingFlag = true;
        this.RegionCodeOptionsLoading = true;
        var selectbox1_source = "RegionCode";
        var selectbox1_destination = "RegionCodeOptions";
        var selectbox1_url = "api/year-wise-lom/region-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          AreaCode: AreaCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (AreaCode == "") {
          message += "Area should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.CurrentYearId;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode
      );
      if (YearCode != "" && ZoneCode != "") {
        this.LoadingFlag = true;
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          AllOption: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getDesignationCodeOptions() {
      console.log("DesignationCodeOptions called");
      this.LoadingFlag = true;
      this.DesignationCodeOptionsLoading = true;
      var selectbox1_source = "DesignationCode";
      var selectbox1_destination = "DesignationCodeOptions";
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 5,
        DesignationTypeId: [527, 528],
        ExceptDesignationId: [1, 5, 6, 7],
        AllOption: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var SearchId = this.SearchId;
      var ApprovalStatus = this.ApprovalStatus;
      var MigrationStatus = this.MigrationStatus;
      var UserInterface = SearchId == 1 ? 1 : 3;
      console.log(
        "validate1=" +
          validate1 +
          ", SearchId=" +
          SearchId +
          ", UserInterface=" +
          UserInterface
      );

      if (validate1) {
        this.SearchFlag = true;
        this.tableData1 = [];
        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.Modules = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/reports/directory-information/lists";
        var upload = {
          UserInterface: 1,
          YearCode: this.JciYearCode,
          ZoneCode: this.ZoneCode,
          LomCode: this.LomCode,
          DesignationCode: this.DesignationCode,
          ApprovalStatus: ApprovalStatus != "" ? ApprovalStatus : null,
          MigrationStatus: MigrationStatus >= 0 ? MigrationStatus : null,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            table = response.data.TableContent;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            var options = table.TableOptions;

            thisIns.tableOptions1 = options;
            thisIns.ExcelDownloadFlag = options.ExcelDownloadButtonFlag;
            thisIns.Modules = options.Modules;

            if (flag == 1) {
              thisIns.tableColumns1 = table.TableHeader;
              thisIns.tableData1 = table.TableData;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    regenerateRecords() {
      console.log("regenerateRecords is called");

      var validate1 = true;

      if (validate1) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var upload = {
          UserInterface: 2,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (LomId == "") {
          message += "Your session is expired. Kindly login again. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    generateReportExcel() {
      console.log("generateReportExcel is called");

      // var validate1 = this.$refs.form2.validate();
      var FromDate = "2021-08-04";
      var ToDate = "2021-08-05";
      var validate1 = true;
      console.log(
        "validate1=" +
          validate1 +
          ", FromDate=" +
          FromDate +
          ", ToDate=" +
          ToDate
      );

      if (validate1) {
        this.ExcelFileName = "";
        this.ExcelLoadingFlag = true;
        this.ExcelFileFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url =
          server_url + "api/reports/directory-information/generate-excel";
        var upload = {
          UserInterface: 1,
          YearCode: this.JciYearCode,
          ZoneCode: this.ZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()

            thisIns.ExcelLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ExcelFileName = records.ExcelFileName;
              thisIns.ExcelDownloadFlag = records.ExcelDownloadFlag;
              thisIns.ExcelFileFlag = true;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ExcelLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt +=
          "<br/>Member<b> " + tr.MembershipId + " - " + tr.MemberName + "</b>";
        htmlTxt += "<br/>Designaton<b> " + tr.Designaton + "</b>";
        htmlTxt += "<br/>Zone Name<b> " + tr.Zone + "</b>";
        htmlTxt += "<br/>LO Name<b> " + tr.LOM + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";

        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRecord(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    deleteRecord(tr) {
      // add todo in store and clear all fields in dialog
      console.log("deleteRecord called");

      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });

      if (n1 > 0) {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/member-delete";
        var upload = {
          UserInterface: 3,
          OrganizationType: 3,
          Year: this.JciYearCode,
          Zone: this.ZoneCode,
          Lom: tr.LOMId,
          Member: tr.MemberId,
          Designation: tr.DesignatonId,
          YearwiseOfficesHeld: tr.YearwiseOfficeHeldId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "Kindly select one member to delete";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>