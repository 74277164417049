<template>
  <div id="roles-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitForm"
          >
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required field
                  </p>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Category</h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="CategoryOptions"
                    :rules="CategoryRules"
                    :loading="CategoryOptionsLoading"
                    v-model="Category"
                    hint="Select a program visibility"
                    persistent-hin
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Question Type</h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="QuestionTypeOptions"
                    :rules="QuestionTypeRules"
                    :loading="CQuestionTypeOptionsLoading"
                    v-model="QuestionType"
                    hint="Select a program visibility"
                    persistent-hin
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span> Question Text</h6>
                  </label>
                  <v-text-field
                    v-model="QuestionText"
                    :rules="QuestionTextRules"
                    placeholder="Question Text"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6><span class="text-danger">*</span>Questions Order</h6>
                  </label>
                  <v-text-field
                    v-model="OrderColumn"
                    :rules="OrderColumnRules"
                    placeholder="Questions Order"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col> -->
                <!-- <v-row> -->
                <v-col
                  cols="12"
                  md="12"
                  v-if="
                    QuestionType == 1 ||
                    QuestionType == 2 ||
                    QuestionType == 3 ||
                    QuestionType == 7 ||
                    QuestionType == 8 ||
                    QuestionType == 10 ||
                    QuestionType == 11
                  "
                >
                  <h5><span class="text-danger">*</span>Question Validation</h5>
                </v-col>
                <br />
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                  md="3"
                  v-if="QuestionType == 5 || QuestionType == 12"
                >
                  <label>
                    <h6>Max Size<small> (Optional)</small></h6>
                  </label>
                  <v-text-field
                    v-model="MaxSize"
                    :rules="MaxSizeRules"
                    placeholder="Max Size"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                  md="3"
                  v-if="QuestionType == 5 || QuestionType == 12"
                >
                  <label>
                    <h6>Min Size <small>(Optional) </small></h6>
                  </label>
                  <v-text-field
                    v-model="MinSize"
                    :rules="MinSizeRules"
                    placeholder="Min Size"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                  md="3"
                  v-if="QuestionType == 1 || QuestionType == 2"
                >
                  <label>
                    <h6>Max Characters <small>(Optional) </small></h6>
                  </label>
                  <v-text-field
                    v-model="MaxChar"
                    :rules="MaxCharRules"
                    placeholder="Min Character"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <!-- </v-row> -->
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  v-if="
                    QuestionType == 3 ||
                    QuestionType == 7 ||
                    QuestionType == 8 ||
                    QuestionType == 11
                  "
                >
                  <label>
                    <h6>
                      <span class="text-danger">*</span>Question Options
                      <small>(separete using comma)</small>
                    </h6>
                  </label>
                  <v-textarea
                    v-model="QuestionOptions"
                    :rules="QuestionOptionsRules"
                    placeholder="Question Options"
                    outlined
                    dense
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    AddRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      MaxSize: "",
      MaxSizeRules: [],

      records: {},

      QuestionText: "",
      QuestionTextRules: [(v) => !!v || "Question Text is required"],

      OrderColumn: 1,
      OrderColumnRules: [(v) => !!v || "Order is required"],

      MinSize: "",
      MinSizeRules: [],

      MaxSize: "",
      MaxSizeRules: [],

      MaxChar: "",
      MaxCharRules: [],

      CategoryRules: [(v) => !!v || " category is required"],
      Category: "",
      CategoryOptions: [
        { value: "", text: "--Select Category--" },
        { value: 1, text: "LDMT" },
        { value: 2, text: "LAV" },
      ],
      CategoryOptionsLoading: false,

      QuestionTypeRules: [(v) => !!v || " Question Type is required"],
      QuestionType: "",
      QuestionTypeOptions: [
        { value: "", text: "--Select QuestionType--" },
        { value: 1, text: "Text" },
        { value: 2, text: "TextArea" },
        { value: 3, text: "Dropdown" },
        { value: 4, text: "Date" },
        { value: 5, text: "File" },
        { value: 6, text: "Time" },
        { value: 7, text: "Radio" },
        { value: 8, text: "Checkbox" },
        { value: 9, text: "Url" },
        { value: 10, text: "Number" },
        { value: 11, text: "Rating" },
        { value: 12, text: "Image" },
      ],
      QuestionTypeLoading: false,

      QuestionOptions: "",
      QuestionOptionsRules: [(v) => !!v || "Question Optional is required"],

      Year: "",
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      this.pageData();
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getDesignationCodeOptions(
          "NationalDesignation",
          "NationalDesignationOptions",
          1
        );
        this.getDesignationCodeOptions(
          "ZoneDesignation",
          "ZoneDesignationOptions",
          2
        );
        this.getDesignationCodeOptions(
          "LomDesignation",
          "LomDesignationOptions",
          3
        );
        this.getDesignationCodeOptions(
          "SmaNgpDesignation",
          "SmaNgpDesignationOptions",
          4
        );
        this.getDesignationCodeOptions(
          "SmaZgpDesignation",
          "SmaZgpDesignationOptions",
          5
        );
      }
    },
    NationalDesignationOptions: function () {
      console.log("watch NationalDesignationOptions");
      this.LoadingFlag = false;
    },
    ZoneDesignationOptions: function () {
      console.log("watch ZoneDesignationOptions");
      this.LoadingFlag = false;
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.StartupLoadingFlag = false;
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
      this.getTableRecords();
    },
    LomDesignationOptions: function () {
      console.log("watch LomDesignationOptions");
      this.LoadingFlag = false;
    },
    SmaNgpDesignationOptions: function () {
      console.log("watch SmaNgpDesignationOptions");
      this.LoadingFlag = false;
    },
    SmaZgpDesignationOptions: function () {
      console.log("watch SmaZgpDesignationOptions");
      this.LoadingFlag = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lo_training_question",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      console.log(this.ResultFlag);
      this.$emit("hideDialog", this.ResultFlag);
    },
    pageData() {
      console.log("pageData called");
      this.StartupLoadingFlag = true;
      this.getJciYearCodeOptions();
    },
    getDesignationCodeOptions(source, destination, OrganizationType) {
      console.log("DesignationCodeOptions called");
      this.LoadingFlag = true;
      var selectbox1_source = source;
      var selectbox1_destination = destination;
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        OrganizationTypeId: OrganizationType,
        ExceptDesignationId: [],
        AllOption: 0,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getJciYearCodeOptions() {
      this.LoadingFlag = true;
      this.JciYearCodeOptionsLoading = true;
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      let text = this.QuestionOptions;
      const optionsArray = text.split(" ").map((option) => option.trim());

      if (validate1) {
        var upload = {
          Category: this.Category,
          QuestionType: this.QuestionType,
          QuestionText: this.QuestionText,
          MaxSize: this.MaxSize,
          MinSize: this.MinSize,
          MaxChar: this.MaxChar,
          QuestionOptions: this.QuestionOptions,
          OrderColumn: this.OrderColumn,
        };

        console.log({ upload });

        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-training-question/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#roles-create {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>