<template>
  <div id="project-details-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-title></v-card-title>
      <v-card-text>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="confirmAlert"
        >
          <v-container class="py-0">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">* </span> Month Name</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="MonthOptions"
                  :rules="MonthRules"
                  v-model="Month"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" sm="12" lg="4" md="6">
                <h6><span class="text-danger">*</span> Portfolio</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="PortfolioOptions"
                  :rules="PortfolioRules"
                  v-model="Portfolio"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="12" sm="12" lg="4" md="6">
                <h6><span class="text-danger">*</span> Event Name</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="EventOptions"
                  :rules="EventRules"
                  v-model="Event"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="eventData != ''">
            <h4 class="blue--text">Event Details</h4>
            <br />
            <v-row>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Event Date</h6>
                <h5 class="text-dark">{{ eventData.DateFromTxt }}</h5>
              </v-col>

              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Portfolio Type</h6>
                <h5 class="text-dark">{{ eventData.PortfolioName }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Event Name</h6>
                <h5 class="text-dark">{{ eventData.JciEventName }}</h5>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6>Event Image</h6>
                <h5>
                  <viewer rebuild :images="eventData.WebsiteImages">
                    <img
                      style="padding: 5px"
                      width="100"
                      height="100"
                      :src="src"
                      :key="src"
                      v-for="src in eventData.WebsiteImages"
                    />
                  </viewer>
                  <viewer rebuild :images="eventData.InvitationImages">
                    <img
                      style="padding: 5px"
                      width="100"
                      height="100"
                      :src="src"
                      :key="src"
                      v-for="src in eventData.InvitationImages"
                    />
                  </viewer>
                  <viewer rebuild :images="eventData.EventImages">
                    <img
                      style="padding: 5px"
                      width="100"
                      height="100"
                      :src="src"
                      :key="src"
                      v-for="src in eventData.EventImages"
                    />
                  </viewer>
                </h5>
                <!-- <h5 class="text-dark">{{ eventData.WebsiteImages }}</h5> -->
              </v-col>
              <v-col cols="12" sm="12" lg="8" md="12">
                <h6>Event Description</h6>
                <h6 class="text-dark">{{ eventData.JciEventDescription }}</h6>
              </v-col>
            </v-row>
            <!-- <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <th>
                      <h5>Event Date</h5>
                    </th>
                    <th class="text-left">
                      <h5>Event Name</h5>
                    </th>
                    <th>
                      <h5>Event Image</h5>
                    </th>
                    <th>
                      <h5>Event Description</h5>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td max-width="40">
                      {{ eventData.DateFromTxt }}
                    </td>
                    <td>
                      {{ eventData.JciEventName }}
                    </td>
                    <td>
                      <viewer rebuild :images="eventData.WebsiteImages">
                        <img
                          style="padding: 5px"
                          width="100"
                          height="100"
                          :src="src"
                          :key="src"
                          v-for="src in eventData.WebsiteImages"
                        />
                      </viewer>
                    </td>
                    <td>
                      {{ eventData.JciEventDescription }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table> -->

            <!-- </v-row> -->
          </v-container>
          <v-row>
            <v-col align="center" cols="12" md="12">
              <v-btn
                :disabled="eventData == ''"
                @click.prevent="addEvent"
                :loading="SubmitFlag"
                elevation="30"
                shaped
                tile
                medium
                color="#1db954"
                class="font-size-h6 px-6 mr-3 white--text"
              >
                Add
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      required: true,
      type: Boolean,
    },
    pageTitle: {
      required: true,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      addButtonFlag: true,

      eventData: "",
      Project: {},

      MonthRules: [(v) => !!v || "Month Name is required"],
      Month: "",
      MonthOptions: [],
      MonthOptionsLoading: false,

      PortfolioRules: [(v) => !!v || "Portfolio is required"],
      Portfolio: "",
      PortfolioOptions: [],
      PortfolioOptionsLoading: false,

      EventRules: [(v) => !!v || "Event Name is required"],
      Event: "",
      EventOptions: [],
      EventOptionsLoading: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;
      }
    },
    MonthOptions: function () {
      console.log("watch MonthOptions");
      this.MonthOptionsLoading = false;
    },
    PortfolioOptions: function () {
      console.log("watch PortfolioOptions");
      this.PortfolioOptionsLoading = false;
    },
    EventOptions: function () {
      console.log("watch EventOptions");
      this.EventOptionsLoading = false;
    },
    Month: function () {
      console.log("Portfolio called");
      this.Portfolio = "";
      this.Event = "";
      this.eventData = "";
      this.getEventOptions();
      // this.getPortfolioOptions();
    },
    // Portfolio: function () {
    //   console.log("Portfolio called");
    //   this.Event = "";
    //   this.eventData = "";
    //   this.getEventOptions();
    // },
    Event: function () {
      console.log("Event called");
      this.eventData = "";
      this.searchForm();
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;

      var tr = this.recordData;
      console.log({ tr });

      this.getMonthOptions();
      this.LoadingFlag = false;
    },
    closePrompt() {
      console.log("closePrompt called");
      var tr = this.recordData;
      console.log({ tr });

      var id = tr.SNo;
      console.log({ id });

      this.Project = {
        Index: id,
        EventId: "",
        EventName: "",
        EventDate: "",
        PortfolioId: "",
        PortfolioName: "",
        EventDescription: "",
        EventPhoto: "",
        EventImages: "",
        InvitationImages: "",
      };
      this.$emit("hideAddRecordPrompt", this.Project);
    },
    getMonthOptions() {
      console.log("getMonthOptions called");
      this.MonthOptionsLoading = true;
      var selectbox1_source = "Month";
      var selectbox1_destination = "MonthOptions";
      var selectbox1_url = "api/jci-month/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getPortfolioOptions() {
      console.log("getPortfolioOptions called");
      this.PortfolioOptionsLoading = true;
      var selectbox1_source = "Portfolio";
      var selectbox1_destination = "PortfolioOptions";
      var selectbox1_url = "api/portfolio/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getEventOptions() {
      console.log("getEventOptions called");
      this.EventOptionsLoading = true;
      var selectbox1_source = "Event";
      var selectbox1_destination = "EventOptions";
      var selectbox1_url = "api/jci-events/options";

      var Month = this.Month;
      console.log({ Month });

      if (Month > 0) {
        var selectbox1_conditions_array = {
          UserInterface: 2,
          Month: Month,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.tableData1 = [];
        this.selected1 = [];
        this.SearchFlag = true;
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/show";
        var upload = {
          UserInterface: 1,
          EventCode: this.Event,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.eventData = records;

              thisIns.toast("success", output);
            } else {
              console.log("error=" + output);
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindy fill required fields and then submit";
        this.toast("error", message);
      }
    },
    addEvent() {
      console.log("addEvent called");

      var tr = this.recordData;
      console.log({ tr });

      var id = tr.SNo;
      console.log({ id });

      var eventData = this.eventData;
      console.log({ eventData });

      if (eventData != "") {
        this.Project = {
          Index: id,
          EventId: eventData.JciEventId,
          EventName: eventData.JciEventName,
          EventDate: eventData.DateFrom1,
          PortfolioId: eventData.PortfolioId,
          PortfolioName: eventData.PortfolioName,
          EventDescription: eventData.JciEventDescription,
          EventPhoto: eventData.WebsiteImages,
          EventImages: eventData.EventImages,
          InvitationImages: eventData.InvitationImages,
        };
        this.$emit("hideAddRecordPrompt", this.Project);
        this.eventData = "";
        this.Project = {};
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss"></style>
